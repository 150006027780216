<template>
  <section class="pd-20 bg" style="min-height: 95vh">
    <aPageHeader class="pd-0 mb-0">
      <template #title>
        <h1 class="iblock relative top5">
          <img
            src="@/assets/images/dashboard/banks/ico.png"
            alt="img"
            width="30"
          />
          BANCOS
        </h1>
      </template>
      <template #extra></template>
    </aPageHeader>

    <aRow type="flex" :gutter="15">
      <aCol class="sidebar" flex="360px">
        <BankSkeleton
          v-if="!showBankData"
          :userIsVerified="userIsVerified"
          @verificationSuccessfully="verificationSuccessfully"
        />
        <BankSidebarSection
          v-if="showBankData"
          :bankAccount="bankAccount"
          :userIsVerified="userIsVerified"
          @verificationSuccessfully="verificationSuccessfully"
          @updateBankData="updateBankData"
          @updateTransactionsList="updateTransactionsList"
        />
      </aCol>
      <aCol class="content" flex="auto">
        <BankContentSection
          v-if="showBankData && !loadingBankData"
          :bankAccount="bankAccount"
          ref="BankContentSection"
        />
      </aCol>
    </aRow>
  </section>
</template>

<script>
import BankSkeleton from "@/components/finances/sections/BankSkeleton.vue";
import BankSidebarSection from "@/components/finances/sections/BankSidebarSection.vue";
import BankContentSection from "@/components/finances/sections/BankContentSection.vue";

export default {
  name: "BanksPage",
  components: { BankSkeleton, BankSidebarSection, BankContentSection },
  data() {
    return {
      showBankData: false,
      userIsVerified: false,
      loadingBankData: false,
      bankAccount: {},
    };
  },
  mounted() {
    //  this.getBankById(22);
  },
  methods: {
    async getBankById(bankId) {
      this.loadingBankData = true;
      try {
        const { data } = await this.$http.get(
          `/company-bank-accounts/details?id=${bankId}`
        );
        this.bankAccount = data;
        return data;
      } catch (error) {
        error;
      } finally {
        this.loadingBankData = false;
      }
    },
    updateBankData() {
      this.getBankById(this.bankAccount.id);
    },
    updateTransactionsList() {
      this.$refs.BankContentSection.$emit("updateTransactionsList");
    },
    verificationSuccessfully(bankId, verificationData) {
      console.log("verificationSuccessfully", bankId, verificationData);
      this.getBankById(bankId).then(() => {
        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: bankId,
          module: "company-bank-accounts",
          action: "view-bank-transactions",
          description: `<b>${this.$store.state.userData.first_name} ${
            this.$store.state.userData.last_name
          }</b> visualizou as transações do banco <b>ID ${bankId} - ${
            this.bankAccount.company_name ?? ""
          }</b>.`,
        });
      });
      this.showBankData = true;
      this.userIsVerified = true;
    },
  },
};
</script>

<style lang="css" scoped>
.content {
  flex: 1 1 !important;
}
</style>

<style lang="sass">
.banks-modalx
  .ant-modal-title
    font-size: 15px
    font-weight: 400
    color: #444
  .ant-modal-header
    background: #a8b3bf
    padding: 16px 10px
</style>
